import format from "date-fns/format";
import getTime from "date-fns/getTime";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ko } from "date-fns/locale";
import differenceInDays from "date-fns/differenceInDays";

// ----------------------------------------------------------------------

// Learn more: https://date-fns.org/v2.25.0/docs/format

// dd MM yyyy = 02 10 2021
// dd MMM yyyy = 02 Jan 2021
// dd MMMM yyyy p = 02 February 2021 3:57 PM
// dd MMMM yyyy hh:mm = 02 February 2021  03:58
// dd MMMM yyyy hh:mm:ss = 02 February 2021 03:59:01

export function fDate(date, option) {
  return date
    ? format(new Date(date), option || "dd MMM yyyy", { locale: ko })
    : null;
}
export function fDateToKST(date, option) {
  if (date) {
    // console.log(date)
    const utcDate = new Date(date);
    const kstDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: "Asia/Seoul" })
    );
    // console.log(
    //   kstDate,
    //   format(kstDate, option || "dd MMM yyyy", { locale: ko })
    // );
    return format(kstDate, option || "dd MMM yyyy", { locale: ko });
  } else {
    return null;
  }
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : null;
}

export function fToNow(date, { addSuffix = true, includeSeconds = false }) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: addSuffix,
        includeSeconds: includeSeconds,
      })
    : null;
}

export function fGap(date) {
  return differenceInDays(new Date(), date);
}
