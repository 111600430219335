// ----------------------------------------------------------------------

export default function Menu(theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          // padding: theme.spacing(0),
          borderBottom: '1px solid',
          borderColor: theme.palette.grey.soft,
          '&:hover, &:focus': {
            backgroundColor: 'transparent',
          },
          '&:last-of-type': {
            borderBottom: 'none',
          },
          '&.Mui-selected': {
            // backgroundColor: theme.palette.action.selected,
            backgroundColor: 'white',
            '&:hover, &:focus': {
              // backgroundColor: theme.palette.action.hover,
              backgroundColor: 'transparent',
            },
          },
          '& .MuiListItemIcon-root': {
            minWidth: 'unset',
          },
        },
      },
    },
  };
}
