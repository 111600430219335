import { Box } from "@mui/material";

// ----------------------------------------------------------------------

export default function SvgIconStyle({ src, size, origin, color, sx }) {
  return (
    <Box
      component="span"
      sx={{
        width: size || 24,
        height: size || 24,
        minWidth: size || 24,
        minHeight: size || 24,
        backgroundSize: "contain",
        display: "inline-block",

        WebkitMask: origin ? "unset" : `url(${src}) no-repeat center / contain`,
        background: origin
          ? ` url(${src}) no-repeat center /contain`
          : color ?? "currentColor",
        mask: origin ? "unset" : `url(${src}) no-repeat center / contain`,
        ...sx,
      }}
    />
  );
}
