import { useState, useEffect } from "react";
import Cart from "layout/cart";
import {
  Container,
  Typography,
  Box,
  Stack,
  Button,
  Select,
} from "@mui/material";
import {
  CommonCounter,
  CommonRadio,
  CategoryOption,
  CommonModal,
} from "components";
import {
  ItemCard,
  GoListButton,
  AddCartButton,
  FastestButton,
  Loading,
} from "components/unitComponent";
import SelectTime from "components/SelectTime";
import SymbolIcon from "assets/icons/symbol.svg";

import Api from "utils/wApi/index";
import useCart from "../store";
import { fDate } from "utils/formatTime";
import { getItemType } from "utils/common";

// translations
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";

// 장바구니 담기 함수
import {
  addNormal,
  addNormalOptions,
  addTime,
  addTimeOption,
} from "utils/addCart";

export default function Detail() {
  const { cart, removeAllCart, removeCart, callCart } = useCart(
    (state) => state
  );
  const [selected, setSelected] = useState(null);
  const [selectedMode, setSelectedMode] = useState();
  const [selectedType, setSelectedType] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [fastest, setFastest] = useState(false); //빠른시간 구매
  const fastestMode = fastest && selectedMode === "TIME"; //빠른시간구매 모드 여부
  const [open, setOpen] = useState(null); //빠른시간 구매 안내 모달
  const [fastestData, setFastestData] = useState(null); //빠른시간구매 상품 데이터

  const [count, setCount] = useState(0); //구매수량

  const [time, setTime] = useState(null); //선택된 시간

  const [callList, setCallList] = useState(0); //선택된 시간

  // [typeOption] :: 시간 타입 옵션(id 값으로 저장)
  const [typeOption, setTypeOption] = useState(null);
  // [normalOption] :: 일반 옵션(id 값으로 저장)
  const [normalOption, setNormalOption] = useState(null);

  // ----- 필수/선택 옵션 카테고리 -----
  // data.normal.topOptions === orderDescending에 따라 순서 변경
  const [initOption, setInitOption] = useState([]);
  // 선택한 카테고리 {아이디, 카테고리명} 배열
  const [categoryArr, setCategoryArr] = useState([]);
  // 선택한 옵션 배열
  const [categoryOption, setCategoryOption] = useState([]);

  // 선택한 옵션의 남은 재고
  const [maxOptionQty, setMaxOptionQty] = useState(0);

  const { id } = useParams();

  // functions =====================================================

  // 상품 데이터 확인
  async function init() {
    try {
      const {
        data: { data },
      } = await Api.getDetail(null, +id);
      setSelected(data);
      setSelectedMode(data.mode);
      setSelectedType(getItemType(data));

      // ----- 선택/필수 옵션 카테고리 데이터 세팅 -----
      if (
        data?.normal?.optionMode === "TOP" ||
        data?.normal?.optionMode === "SELECT"
      ) {
        // 1. orderDescending 순서에 따라 카테고리 정렬
        let changeCategory = [...data.normal.topOptions].sort(
          (a, b) => a.orderDescending - b.orderDescending
        );

        // 2. orderDescending 순서에 따라 옵션 정렬
        changeCategory.map((category, index) => {
          const arr = category.options.sort(
            (a, b) => a.orderDescending - b.orderDescending
          );

          changeCategory[index].options = arr;
        });

        // 3. 옵션 데이터 정렬 변경하여 적용
        setInitOption(changeCategory);

        // 4. 초기값 설정
        if (data.normal.optionMode === "TOP") {
          // 필수 카테고리일 경우
          // -> 첫번째 옵션 카테고리 > 첫번째 옵션 아이디
          setCategoryArr([
            { id: changeCategory[0].id, name: changeCategory[0].name },
          ]);
        } else {
          // 선택 카테고리일 경우 -> 전체 카테고리 노출
          // mode === 'SELECT'
          setCategoryArr([
            ...changeCategory.map((x) => {
              return { id: x.id, name: x.name };
            }),
          ]);
        }

        // 5. 옵션은 초기화
        setCategoryOption([]);
      }
      // ----- 선택/필수 옵션 카테고리 데이터 세팅 -----
    } catch (error) {
      alert(error.message);
    }
  }

  // 장바구니 담기
  async function addCart() {
    // 필수옵션 미선택 시
    if (selectedType === "REQUIRED_CATEGORY" && categoryOption?.length === 0) {
      alert("필수 옵션을 선택해주세요");
    } else {
      switch (selectedType) {
        case "NORMAL_SINGLE":
          await addNormal({
            id: selected.id,
            buyCount: count,
            price: totalPrice(),
          });
          callCart();
          // init();
          break;
        case "NORMAL_OPTION":
          await addNormalOptions({
            id: selected.id,
            buyCount: count,
            price: totalPrice(),
            optionId: normalOption,
            optionMode: selected?.normal.optionMode,
          });
          callCart();
          // init();
          break;

        // 필수,선택 옵선 카테고리
        case "REQUIRED_CATEGORY":
        case "SELECT_CATEGORY":
          await addNormalOptions({
            id: selected.id,
            buyCount: count,
            price: totalCatePrice(),
            optionId: categoryOption,
            optionMode: selected?.normal.optionMode,
          });
          callCart();
          // init();
          break;
        case "TIME_SINGLE":
          await addTime({
            id: fastest ? fastestData.id : time.id,
            buyCount: count,
            price: selected.price * count,
          });
          callCart();
          // 재고 수량 업데이트때문에 시간 리스트 한번 더 호출
          setCallList((prev) => (prev += 1));
          break;
        case "TIME_OPTION":
          await addTimeOption({
            id: fastest ? fastestData.id : time.id,
            buyCount: count,
            price: totalTypePrice(),
            optionId: typeOption,
          });
          callCart();
          // 재고 수량 업데이트때문에 시간 리스트 한번 더 호출
          setCallList((prev) => (prev += 1));
          break;

        default:
          alert("담을 수 있는 상품이 없습니다.");
          break;
      }

      // 초기화
      setCount(0);
      setTypeOption(null);
      setNormalOption(null);
      setCategoryOption([]);

      init();
    }
  }

  // 상품 가장 빠른 시간 불러오기
  async function getFastest() {
    const typeMode = selected.time.type === "TYPE";
    const type = typeMode ? "fastestType" : "fastestSingle";
    try {
      const { data } = await Api[type](null, null, {
        params: {
          idMenu: selected.id,
          idMenuType: typeMode ? typeOption : undefined,
          qty: count,
        },
      });
      setFastestData(data);
      console.log("가장 빠른 시간:::", data);
    } catch (error) {
      alert(error.message);
    }
  }

  // 일반상품 (단일,옵션) 총 가격 계산하기
  const totalPrice = () => {
    const NORMAL_OPTION = selectedType === "NORMAL_OPTION";
    const optionPrice = NORMAL_OPTION
      ? selected?.normal?.options?.find((v) => v.id === normalOption).price
      : 0;
    return (selected?.price + optionPrice || 0) * count;
  };

  // 일반상품 카테옵션 총 가격 계산하기
  const totalCatePrice = () => {
    let arr = [];
    let sum = 0;
    let total = 0;

    // if (country === "JP") {
    // 	if (categoryOption.length > 0) {
    // 		arr = categoryOption.map((x) => x.priceJPY);
    // 		arr.forEach((p) => (sum += p));
    // 		total = (selected.priceJPY + sum || 0) * count;
    // 	} else {
    // 		total = selected.priceJPY * count;
    // 	}
    // } else if (country === "US") {
    // 	if (categoryOption.length > 0) {
    // 		arr = categoryOption.map((x) => x.priceUSD);
    // 		arr.forEach((p) => (sum += p));
    // 		total = (selected.priceUSD + sum || 0) * count;
    // 	} else {
    // 		total = selected.priceUSD * count;
    // 	}
    // } else {
    if (categoryOption.length > 0) {
      arr = categoryOption.map((x) => x.price);
      arr.forEach((p) => (sum += p));
      total = (selected.price + sum || 0) * count;
    } else {
      total = selected.price * count;
    }
    // }

    // return (selected?.price + price || 0) * count;
    return total;
  };

  // 시간 상품 옵션 총 가격 계산
  const totalTypePrice = () => {
    const optionPrice = selected.time.menuTypes.find(
      (v) => v.id === typeOption
    ).price;
    return (selected.price + optionPrice) * count;
  };

  // 상품 최대 수량 계산
  const getLimit = () => {
    let max = 0;

    // if (selectedMode === "TIME" && fastest) {
    // 	return !!selected?.salesLimitOnce
    // 		? Math.min(...[fastestData?.maxQty, selected?.salesLimitOnce])
    // 		: fastestData?.maxQty;
    // } else if (selectedMode === "TIME" && !fastest) {
    // 	return selected?.salesLimitOnce
    // 		? Math.min(...[time?.maxQty, selected?.salesLimitOnce])
    // 		: time?.maxQty;
    // }

    // 시간 상품일 경우
    if (selectedMode === "TIME") {
      max =
        selected?.salesLimitOnce || selected?.salesLimit
          ? Math.min(
              ...[
                time?.maxQty,
                selected?.salesLimitOnce || selected?.salesLimit,
              ]
            )
          : time?.maxQty;
    } else if (selectedType === "REQUIRED_CATEGORY") {
      // 필수 옵션 상품일 경우

      max =
        selected?.salesLimitOnce || selected?.salesLimit
          ? Math.min(...[
              selected?.salesLimitOnce||
              selected?.salesLimit,
              maxOptionQty]
            )
          : maxOptionQty;

      console.log("maxOptionQty", maxOptionQty);
    } else {
      max =
        selected?.salesLimitOnce || selected?.salesLimit
          ? Math.min(...[
              selected?.qty,
              selected?.salesLimitOnce||
              selected?.salesLimit]
            )
          : selected?.qty;
    }

    console.log("최대 선택가능 수량 : ", max);

    return max;
  };

  // 시간 선택시 최대 수량 조정
  const setMaxCount = (maxCount) => {
    maxCount < count ? setCount(maxCount) : null;
  };

  // 필수/선택 옵션 카테고리 클릭 이벤트
  const handleCategoryOption = (category, option, index) => {
    // 선택한 갯수 초기화
    setCount(0);

    // 필수 옵션 카테고리일 경우
    if (selectedType === "REQUIRED_CATEGORY") {
      // -------- 선택한 카테고리 업데이트 --------
      // 하위 카테고리 아이디가 있으면 하위 옵션 추가선택
      if (index === 0 && option.childCategoryId === null) {
        setCategoryArr([
          {
            id: category.id,
            name: category.name,
          },
        ]);
      } else if (
        option.childCategoryId === null &&
        index < categoryOption.length
      ) {
        categoryArr.splice(index + 1, categoryArr.length - index + 1);
      } else if (option.childCategoryId !== null) {
        setCategoryArr([
          ...categoryArr,
          {
            id: option.childCategoryId,
            name: initOption.filter((x) => x.id === option.childCategoryId)?.[0]
              ?.name,
          },
        ]);
      }
      // -------- 선택한 카테고리 업데이트 --------
    }

    // -------- 선택한 옵션 업데이트 --------
    if (index === 0) {
      setCategoryOption([option]);
    } else {
      if (
        categoryOption[index] !== undefined &&
        index <= categoryOption.length
      ) {
        categoryOption.splice(index, categoryOption.length - index);
      }
      setCategoryOption([...categoryOption, option]);
    }
    // -------- 선택한 옵션 업데이트 --------

    // -------- 필수 옵션 카테고리 + 선택한 옵션재고 최대 수량 계산 --------
    if (selectedType === "REQUIRED_CATEGORY") {
      try {
        let init = selected?.normal?.optionStocks?.filter((x) => {
          return x?.optionId === option?.id && x?.optionStockQty;
        });

		setMaxOptionQty(init?.[0]?.optionStockQty);
      } finally {
        getLimit();
      }
    }
    // -------- 필수 옵션 카테고리 + 선택한 옵션재고 최대 수량 계산 --------
  };

  useEffect(() => {
    if (!fastestMode) return;
    getFastest();
  }, [count, typeOption, fastest]);

  // 시간상품 옵션 최초 선택
  useEffect(() => {
    if (selectedType !== "TIME_OPTION") return;
    setTypeOption(selected?.time?.menuTypes[0]?.id);
  }, [selected]);

  // 일반상품 옵션 최초 선택
  useEffect(() => {
    if (selectedType !== "NORMAL_OPTION") return;
    setNormalOption(selected?.normal?.options[0]?.id);
  }, [selected]);

  // 일반상품 카테고리 옵션 최초 선택
  // useEffect(() => {
  // 	if (selectedType !== "REQUIRED_CATEGORY") return;
  // 	const firstCategory = selected?.normal?.topOptions[0];
  // 	setCategoryOption({
  // 		category: firstCategory.id,
  // 		id: firstCategory.options[0].id,
  // 		price: firstCategory.options[0].price,
  // 	});
  // }, [selected]);

  useEffect(() => {
    if (!selected) return;
    // check=====================
    const temp = {
      NORMAL_SINGLE: "일반상품 단일",
      NORMAL_OPTION: "일반상품 옵션",
      REQUIRED_CATEGORY: "일반상품 필수옵션 카테고리",
      SELECT_CATEGORY: "일반상품 선택옵션 카테고리",
      TIME_SINGLE: "시간상품단일",
      TIME_OPTION: "시간상품 옵션",
    };
    // check=====================
  }, [selected]);

  useEffect(() => {
    init();
  }, []);

  // =================================================================================
  return (
    <Cart noCategory>
      <Container title="kiosk" sx={{ height: "100%", mt: 3.5 }}>
        <Container sx={{ py: 3, height: "100%" }}>
          {loading ? (
            <Loading />
          ) : (
            <Container
              sx={{
                px: "0 !important",
                display: "inline-flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  borderTop: "1px solid",
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  display: "flex",
                  py: "40px",
                }}
              >
                {/* 선택한 상품 정보 */}
                <Box
                  sx={{
                    pointer: "cursor",
                    px: 1,
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  {selected?.thumbnail ? (
                    <Box
                      sx={{
                        backgroundColor: "grey.400",
                        width: 210,
                        minWidth: 210,
                        height: 162,
                        borderRadius: "4px",
                        background: `center/cover no-repeat url(${selected?.thumbnail})`,
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: "grey.400",
                        width: 210,
                        minWidth: 210,
                        height: 162,
                        borderRadius: "4px",
                        background: `center/30% no-repeat #ededed url(${SymbolIcon.src})`,
                        opacity: 0.6,
                      }}
                    ></Box>
                  )}

                  <Box
                    sx={{
                      minHeight: 162,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        fontSize={18}
                        fontWeight={700}
                        mb={1}
                        lineHeight={1}
                      >
                        {selected?.name}
                      </Typography>
                      <Typography
                        fontSize={14}
                        color="grey.middle"
                        lineHeight={1}
                        mb={1}
                      >
                        {/* {selected?.business?.name} */}
                        {selected?.subtitle}
                      </Typography>
                      <Typography
                        fontSize={20}
                        fontWeight={700}
                        color="primary.main"
                        lineHeight={1}
                      >
                        {selected?.qty === 0
                          ? "품절"
                          : `${Number(selected?.price).toLocaleString()}원`}
                      </Typography>
                    </Box>

                    <Button
                      onClick={() => setOpen("detail")}
                      sx={{
                        fontSize: 16,
                        border: "1px solid",
                        padding: "2px 13px",
                        width: "fit-content",
                        borderRadius: "100px",
                        mt: "16px",
                      }}
                    >
                      상세정보
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  {/* 일반상품 + 옵션 */}
                  {selectedType === "NORMAL_OPTION" && (
                    <Box sx={{ mt: 3 }}>
                      <Typography fontSize={20} fontWeight={700} mb={2}>
                        {/* 옵션을 선택해주세요 */}
                        {t("select-option")}
                      </Typography>

                      <CommonRadio
                        items={selected.normal.options}
                        value={normalOption}
                        onChange={(e) => setNormalOption(e)}
                        tail
                      />
                    </Box>
                  )}

                  {/* 일반상품 + 필수 옵션 카테고리 */}
                  {/* {selectedType === "REQUIRED_CATEGORY" && (
										<Box sx={{ mt: 3 }}>
											<Typography fontSize={20} fontWeight={700} mb={2}>
												옵션을 선택해주세요
												{t("select-option")}
											</Typography>
											<CategoryOption
												items={selected.normal.topOptions}
												value={categoryOption}
												onCategoryChange={(categoryId, i) => {
													const { id, price } =
														selected.normal.topOptions[i].options[0];
													return setCategoryOption({
														category: categoryId || "",
														id,
														price,
													});
												}}
												onChange={(option) => {
													setCategoryOption((prev) => ({
														...prev,
														id: option.id || "",
														price: option.price,
													}));
												}}
											/>
										</Box>
									)} */}

                  {/* 일반상품 + 필수 or 선택 옵션 카테고리 */}
                  {(selectedType === "REQUIRED_CATEGORY" ||
                    selectedType === "SELECT_CATEGORY") &&
                    categoryArr.length > 0 && (
                      <Box sx={{ mt: 3 }}>
                        <Typography fontSize={20} fontWeight={700} mb={2}>
                          {t("select-option")}
                        </Typography>
                        {categoryArr.map((category, index) => (
                          <>
                            {/* ----- 카테고리 명 ----- */}
                            <Typography
                              key={category.name}
                              variant="subtitle1"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 0.2,
                                mt: 3,
                                mb: 1.2,
                              }}
                            >
                              {category.name}

                              {/* 카테고리 필수 | 선택 */}
                              {selectedType === "REQUIRED_CATEGORY" ? (
                                <Typography
                                  variant="subtitle1"
                                  color="primary.main"
                                >
                                  {/* (필수) */}
                                  {`(${t("necessary")})`}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="subtitle1"
                                  color="grey.200"
                                >
                                  {/* (선택) */}
                                  {`(${t("select")})`}
                                </Typography>
                              )}
                            </Typography>
                            {/* ----- 카테고리 명 ----- */}

                            <Stack
                              direction="row"
                              sx={{ overflowX: "scroll", gap: "10px", pb: 1 }}
                              key={category.id}
                            >
                              {category?.id === initOption[index]?.id
                                ? initOption[index].options.map((option, i) => {
									
                                    return (
                                      <Button
                                        disabled={
                                          selectedType ===
                                            "REQUIRED_CATEGORY" &&
                                          selected?.normal?.optionStocks?.filter(x=> x?.optionId === option?.id)?.[0]?.optionStockQty === 0
                                        }
                                        variant={
                                          (categoryOption.length > 0 &&
                                            categoryOption?.includes(option)) || // selected
                                          (selectedType ===
                                            "REQUIRED_CATEGORY" &&
											selected?.normal?.optionStocks?.filter(x=> x?.optionId === option?.id)?.[0]?.optionStockQty === 0) // disabled
                                            ? "contained"
                                            : "outlined"
                                        }
                                        sx={{
                                          flexDirection: "column",
                                          maxWidth: "200px",
                                          minWidth: "90px",
                                          color: "black",
                                          borderColor: "grey.100",
                                        }}
                                        onClick={() =>
                                          handleCategoryOption(
                                            category,
                                            option,
                                            index
                                          )
                                        }
                                      >
                                        <Typography
                                          color={
                                            (selectedType ===
                                              "REQUIRED_CATEGORY" &&
											  selected?.normal?.optionStocks?.filter(x=> x?.optionId === option?.id)?.[0]?.optionStockQty === 0) ||
                                            (categoryOption.length > 0 &&
                                              categoryOption?.includes(option))
                                              ? "white"
                                              : "black"
                                          }
                                        >
                                          {option?.name}
                                        </Typography>

                                        <Typography
                                          color={
                                            (selectedType ===
                                              "REQUIRED_CATEGORY" &&
                                              selected?.normal?.optionStocks?.filter(x=> x?.optionId === option?.id)?.[0]?.optionStockQty === 0) ||
                                            (categoryOption.length > 0 &&
                                              categoryOption?.includes(option))
                                              ? "white"
                                              : "black"
                                          }
                                        >
                                          {selectedType ===
                                            "REQUIRED_CATEGORY" &&
											selected?.normal?.optionStocks?.filter(x=> x?.optionId === option?.id)?.[0]?.optionStockQty === 0
                                            ? "품절"
                                            : `${
                                                option.price >= 0 ? "+" : "-"
                                              } ${t("price", {
                                                price: Number(
                                                  option?.price
                                                ).toLocaleString(),
                                              })}`}
                                        </Typography>
                                      </Button>
                                    );
                                  })
                                : ""}
                            </Stack>
                          </>
                        ))}
                      </Box>
                    )}

                  {/* 시간 상품 - 옵션 */}
                  {selectedType === "TIME_OPTION" && (
                    <Box sx={{ mt: 3 }}>
                      <Typography fontSize={20} fontWeight={700} mb={2}>
                        {/* 옵션을 선택해주세요 */}
                        {t("select-option")}
                      </Typography>

                      <CommonRadio
                        items={selected.time.menuTypes}
                        value={typeOption}
                        onChange={(e) => setTypeOption(e)}
                        tail
                      />
                    </Box>
                  )}

                  {/*  시간 상품 - 시간 선택 */}
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={4}
                  >
                    <Box>
                      <Typography fontSize={20} fontWeight={700}>
                        {selectedMode === "TIME"
                          ? fastestMode
                            ? t("select-count") //"상품 수량을 선택해 주세요."
                            : t("select-pickup") //"픽업 시간 및 상품 수량을 선택해 주세요."
                          : t("select-count")}
                      </Typography>
                      {selectedMode === "NORMAL" &&
                        (!!selected?.salesLimitOnce ||
                          !!selected?.salesLimit) && (
                          <Typography
                            fontSize={16}
                            fontWeight={500}
                            color="grey.middle"
                            mb={1}
                          >
                            {t("max", {
                              max:
                                selected?.salesLimitOnce ||
                                selected?.salesLimit,
                            })}
                            {/* 1인당 최대 {max}개까지 구매
                          가능합니다. */}
                          </Typography>
                        )}
                    </Box>

                    {/* 가장 빠른 시간 구매 버튼 */}
                    {/* {!fastestMode && selectedMode === "TIME" && (
                      <FastestButton onClick={() => setOpen("fastest")} />
                    )} */}
                  </Stack>

                  {/* 수량 선택 ------------------- */}
                  <Box
                    sx={{
                      display: selectedMode === "TIME" ? "block" : "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    <Box>
                      {selectedMode === "TIME" ? null : (
                        <Typography fontSize={20} fontWeight={700}>
                          {selected?.name}
                        </Typography>
                      )}
                      <Typography
                        mt={selectedMode === "TIME" ? 3.5 : 0}
                        fontSize={selectedMode === "TIME" ? 18 : 16}
                        fontWeight={500}
                        color="grey.middle"
                        mb={1}
                      >
                        {selectedMode === "TIME"
                          ? t("select-count") //"상품 수량을 선택하세요."
                          : ""}
                        {/* `잔여 수량 ${selected?.qty}개` */}
                        {/* t("stock", { count: selected?.qty }) */}
                      </Typography>
                    </Box>

                    <CommonCounter
                      count={count}
                      setCount={setCount}
                      item={selected}
                      max={getLimit()}
                    />
                  </Box>

                  {/* 가장빠른 시간 */}
                  {fastestMode && (
                    <Box sx={{ mt: 5 }}>
                      <Typography fontSize={24}>
                        {/* 가장 빠른 시간  */}
                        {t("fastest-time")}
                        &nbsp;: &nbsp;
                        <Typography
                          component="strong"
                          fontWeight={700}
                          fontSize={32}
                        >
                          {fDate(fastestData?.time, "HH:mm")}
                        </Typography>
                      </Typography>
                    </Box>
                  )}

                  {/* 시간 상품 시간 선택------------------ */}
                  <Box sx={{ mt: 5 }}>
                    {!fastestMode && selectedMode === "TIME" && (
                      <SelectTime
                        {...{ selected, time, setTime, callList, setMaxCount }}
                      />
                    )}
                  </Box>
                </Box>

                {/* 장바구니 담기 버튼 */}
                <AddCartButton
                  onClick={() => addCart()}
                  disabled={!count}
                  t={t}
                />

                {/* 목록보기 버튼 */}
                {/* <GoListButton onClick={() => navigate("/menus")} t={t} /> */}
              </Box>
            </Container>
          )}
        </Container>

        {/*  */}
        <CommonModal
          open={open === "fastest"}
          onClose={() => {}}
          footer={{
            label: t("fastest-buy"),
            sx: { fontWeight: 700, letterSpacing: -1 },
            onClick: () => setFastest(true) & setOpen(null),
          }}
          subFooter={{ label: t("close"), onClick: () => setOpen(null) }}
          sx={{ minWidth: 400 }}
        >
          <Typography fontSize={24} fontWeight={700} mb={1} textAlign="center">
            {t("notice")}
          </Typography>
          <Typography
            fontSize={20}
            color="grey.middle"
            textAlign="center"
            letterSpacing={-0.7}
          >
            {t("fastest-notice")}
          </Typography>
        </CommonModal>

        {/* 상품 상세정보 보기 모달 */}
        <CommonModal
          className="detail-img-modal"
          open={open === "detail"}
          width="90vw"
          onClose={() => {}}
          footer={{
            label: t("close"),
            sx: { fontWeight: 700, letterSpacing: -1 },
            onClick: () => setOpen(null),
          }}
          // subFooter={{ label: t("close"), onClick: () => setOpen(null) }}
          sx={{ minWidth: 400 }}
        >
          <Box sx={{ maxHeight: "75vh", overflowY: "scroll" }}>
            <Box
              dangerouslySetInnerHTML={{
                __html: selected?.information,
              }}
            />
          </Box>
        </CommonModal>
      </Container>
    </Cart>
  );
}
