//ui
import { TextField, Typography, Box } from "@mui/material";

// validation
import { useForm, Controller } from "react-hook-form";
import { inputStyle } from "utils/uiData";

// ----------------------------------------------------------------------

export default function CommonInput({
  validation,
  errors,
  sx,
  children,
  ...other
}) {
  const { control } = useForm({
    mode: "onChange",
  });

  return (
    <>
      {/* {label ? <DotTitle label={label} sx={{ mb: "10px" }} /> : null} */}
      <Controller
        name="inputForm"
        control={control}
        render={({ field }) => (
          <Box>
            <TextField
              fullWidth
              hiddenLabel
              variant="outlined"
              {...field}
              {...validation}
              sx={{
                ...inputStyle(errors),
                ...sx,
              }}
              InputProps={{
                endAdornment: children,
              }}
              {...other}
            />
            <Typography
              variant="caption"
              noWrap={true}
              sx={{
                color: "error.main",
                display: "block",
                mt: "-12px",
                mb: 1.8,
                textAlign: "left",
                overflow: "visible",
              }}
            >
              {errors?.message}
            </Typography>
          </Box>
        )}
      />
    </>
  );
}
