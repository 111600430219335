import { Grid, Typography, Box, Button, Stack } from "@mui/material";
import { CartCard, CartArrow } from "components/unitComponent";
import Slider from "react-slick";

export default function Cart({ cart, t, total, onDeleteAll, onDelete }) {
  const gap = "100px";

  const itemSliderSetting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <CartArrow />,
    prevArrow: <CartArrow prev />,
  };

  return (
    <Box
      sx={{
        backgroundColor: "grey.600",
        py: 2.5,
        height:228
      }}
    >
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          // sx={{mb:'10px'}}
        >
          <Stack direction="row" sx={{ ml: gap }}>
            {/* 총 결제금액 */}
            <Typography fontSize={20} fontWeight={700} mr={1}>
              {t("total-pay")}
            </Typography>
            {/* 가격 */}
            <Typography fontSize={20} fontWeight={700} color="primary.main">
              {t("price", { price: Number(total).toLocaleString() })}
            </Typography>
          </Stack>
          <Button
            onClick={onDeleteAll}
            sx={{
              color: "grey.100",
              fontSize: 18,
              fontWeight: 700,
              textDecoration: "underline",
              mr: '40px',
            }}
          >
            {/* 전체 삭제 */}
            {t("delete-all")}
          </Button>
        </Stack>

        <Box sx={{ overflow: "hidden" }}>
          <Box
            ml={gap}
            sx={{
              minHeight: "120px",
              ".slick-track": {
                mx: "unset !important",
                ".slick-slide": {
                  display: "inline-block",
                  pr: 1.5,
                },
              },
            }}
          >
            <Slider {...itemSliderSetting}>
              {cart.length ? (
                cart.map((item, i) => (
                  <CartCard
                    index={i}
                    key={i}
                    item={item}
                    onClick={() => onDelete(item)}
                    t={t}
                  />
                ))
              ) : (
                <Typography fontSize={22} color="grey.200">
                  {t("empty")}
                </Typography>
              )}
            </Slider>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
