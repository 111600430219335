//ui
import {
  FormControlLabel,
  FormControl,
  Typography,
  Box,
  RadioGroup,
  Radio,
} from "@mui/material";
// validation
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function CommonRadio({
  value,
  onChange,
  labelKey,
  valueKey,
  validation,
  items,
  tail,
}) {
  const { t } = useTranslation();
  const { control } = useForm({
    mode: "onChange",
  });

  return (
    <>
      {!!items?.length && (
        <Controller
          name="inputForm"
          control={control}
          render={({ field }) => (
            <FormControl sx={{ minWidth: "300px" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue={valueKey ? items[0][valueKey] : items[0]}
                defaultValue={items[0].id}
                name="radio-buttons-group"
                value={value}
              >
                {items.map((item, i) => (
                  <FormControlLabel
                    key={i}
                    // value={valueKey ? item[valueKey] : item}
                    value={item.id}
                    control={
                      <Radio
                        disabled={item.soldOut}
                        {...validation}
                        onChange={() =>
                          // onChange(valueKey ? item[valueKey] : item)
                          onChange(item.id)
                        }
                      />
                    }
                    sx={{
                      ".MuiFormControlLabel-label": {
                        display: "block",
                        width: "100%",
                        mb: 1,
                      },
                    }}
                    label={
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            fontSize: 20,
                          }}
                        >
                          {item.name}
                          {item.soldOut ? ` <품절>` : ""}
                        </Typography>

                        {tail ? (
                          <Typography
                            sx={{
                              display: "inline",
                              textAlign: "end",
                              width: "100%",
                              whiteSpace: "nowrap",
                              fontSize: 20,
                            }}
                          >
                            +{item?.price?.toLocaleString()}원
                          </Typography>
                        ) : null}
                      </Box>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        />
      )}
    </>
  );
}
