import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Switch(theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          // padding: "9px 13px 9px 12px",
          // width: 58,
          // height: 38,

          width: 48,
          height: 24,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(24px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "#2ECA45"
                    : theme.palette.primary.main,
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 20,
            height: 20,
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.grey[300],
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
              duration: 500,
            }),
          },
        },
        // thumb: {
        //   width: 14,
        //   height: 14,
        //   boxShadow: "none",
        //   border: "1px solid red",
        //   color: `${theme.palette.common.white} !important`,
        // },
        // track: {
        //   opacity: 1,
        //   borderRadius: 10,
        //   backgroundColor: alpha(theme.palette.grey[500], 0.48),
        //   border: "1px solid blue",
        //   height: "100%",
        // },
        // switchBase: {
        //   padding: 12,
        //   left: 3,
        //   "&.Mui-checked": {
        //     transform: "translateX(13px)",
        //     "&+.MuiSwitch-track": {
        //       opacity: 1,
        //     },
        //     "&.Mui-disabled": {
        //       "&+.MuiSwitch-track": {
        //         opacity: 0.48,
        //       },
        //     },
        //   },
        //   "&.Mui-disabled": {
        //     "&+.MuiSwitch-track": {
        //       opacity: 0.5,
        //     },
        //   },
        // },
      },
    },
  };
}
