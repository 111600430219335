import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import React from "react";
import {SvgIconStyle} from "../index";

const AlertDialog = ({open, onClose, logo, title, description}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent sx={{pt: 5, textAlign: 'center'}}>
                <Box>
                    <SvgIconStyle
                        src={logo}
                        origin
                        sx={{height: '45px', width: '240px', mb: 1}}
                    />
                </Box>
                <Typography variant="h3">{title}</Typography>
                <Typography variant="body1">{description}</Typography>
            </DialogContent>
            <Divider/>
            <DialogActions onClick={onClose}>
                <Box sx={{textAlign: 'center', width: '100%', cursor: 'pointer'}}>
                    <Typography variant="body1">취소하기</Typography>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;