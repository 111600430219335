// ----------------------------------------------------------------------

export default function Paper() {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },

      styleOverrides: {
        root: {
          backgroundImage: "none",
          "&:has(.MuiMenu-list .custom-select-item)": {
            borderRadius: "0 0 8px 8px !important",
            left: "20px !important",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            ".MuiList-padding": {
              padding: 0,
            },
          },
          "&:has(.MuiMenu-list .email-select-item)": {
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            ".MuiList-padding": {
              padding: 0,
            },
            ".email-select-item": {
              "&:hover, &.Mui-selected": {
                backgroundColor: "white",
              },
            },
          },
          "&:has(.MuiMenu-list .play-festival-item)": {
            // maxWidth: '140px',
            width: "42%",
            borderRadius: "10px !important",
            right: "0px !important",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            ".MuiList-padding": {
              padding: 0,
            },
          },
          "&:has(.MuiMenu-list .play-more-btn)": {
            borderRadius: "10px !important",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            ".MuiList-padding": {
              padding: 0,
            },
          },
        },
      },
    },
  };
}
