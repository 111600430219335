import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },

      styleOverrides: {
        root: {
          minWidth: "unset",
          marginTop: "unset",
        },

        contained: {
          fontSize: 16,
          fontWeight: 700,
          minHeight: 48,
          borderRadius: 8,
          "&.Mui-disabled": {
            backgroundColor: "#bcbcbc",
            color: theme.palette.common.white,
          },
        },

        outlined: {
          fontSize: 16,
          fontWeight: 700,
          minHeight: 48,
          borderRadius: 8,
          backgroundColor: theme.palette.common.white,
          "&:hover": {
            backgroundColor: "white",
          },
        },
        text: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        sizeLarge: {
          height: 48,
        },
        sizeSmall: {
          height: 24,
          minHeight: 24,
          borderRadius: 4,
          fontSize: 12,
          ":hover": {
            borderColor: "inherit",
            backgroundColor: "inherit",
          },
        },
        colorInherit: {
          backgroundColor: "inherit",
          ":hover": {
            backgroundColor: "inherit",
          },
        },
        // containedInherit: {
        //   color: isLight ? theme.palette.common.white : theme.palette.grey[800],
        //   backgroundColor: isLight
        //     ? theme.palette.grey[800]
        //     : theme.palette.common.white,
        //   "&:hover": {
        //     backgroundColor: isLight
        //       ? theme.palette.grey[700]
        //       : theme.palette.grey[400],
        //   },
        // },

        outlinedInherit: {
          borderColor: alpha(theme.palette.grey[500], 0.32),
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderColor: theme.palette.text.primary,
          },
        },
        textInherit: {
          "&:hover": {
            border: "1px solid red",
            // backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
