import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// components
import Layout from "layout/index";
import { Grid, Container, Typography, Box, Stack } from "@mui/material";
import { BottomButtons, OrderCard } from "components/unitComponent";

// translations

import { useTranslation } from "react-i18next";

import useCart from "../store";

// ----------------------------------------------------------------------

export default function Order() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();

  const { cart, total, callCart, removeCart } = useCart((state) => state);

  useEffect(() => {
    callCart();
  }, []);

  const gap = "56px";

  return (
    <Layout>
      <Container
        sx={{
          px: "0 !important",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          "&:-webkit-scrollbar": { display: "none !important" },
        }}
      >
        <Container
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            pl: `${gap} !important`,
            pr: "0 !important",
            overflow: "scroll",
            "&:-webkit-scrollbar": { display: "none" },
          }}
        >
          <Box sx={{ "&:-webkit-scrollbar": { display: "none" } }}>
            {/* content=============================== */}
            <Box
              sx={{
                mr: 3,
                flexGrow: 1,
                mt: 5,
                "&:-webkit-scrollbar": { display: "none" },
              }}
            >
              <>
                <Box sx={{ "&:-webkit-scrollbar": { display: "none" } }}>
                  {cart.map((item, i) => (
                    <OrderCard
                      key={i}
                      item={item}
                      removeItem={(item) => removeCart(item)}
                    />
                  ))}
                </Box>
              </>
            </Box>
          </Box>
        </Container>

        {/* bottom =============================================== */}
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ bgcolor: "grey.soft", p: 3 }}
          >
            <Typography fontSize={20} fontWeight={700}>
              {t("total-pay")}
              {/* 총 결제금액 */}
            </Typography>
            <Typography fontSize={20} fontWeight={700} color="primary.main">
              {`${Number(total).toLocaleString()}원`}
            </Typography>
          </Stack>
          <BottomButtons
            left={{
              label: t("prev"),
              onClick: () => {
                console.log("::", location.state);
                location?.state?.history ? navigate(-1) : navigate(-2);
              },
            }}
            right={{
              disabled: !total,
              label: t("next"),
              onClick: () => {
                navigate("/info");
              },
            }}
          />
        </Box>
      </Container>
    </Layout>
  );
}
