import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
	openUrlByApp,
	trackAdClick,
	trackAdImpression,
} from "../../utils/ad.utils";
import AdApi from "../../utils/wApi";

/**
 * 1. 탭 - 플레이
 *     - 포지션명 : TabPlay
 * @constructor
 */
const AdMenu = () => {
	const adCategory = "ad_4";
	const adPositionName = "Kiosk";
	const [adID, setAdID] = useState();
	const [adLink, setAdLink] = useState();
	const [adImageUrl, setAdImageUrl] = useState();
	const [adOn, setAdOn] = useState(false);

	useEffect(() => {
		if (adOn && adID) {
			trackAdImpression(adCategory, adPositionName, adID);
		}
	}, [adOn, adID]);

	const handleClick = useCallback(() => {
		if (adOn && adID && adLink) {
			openUrlByApp(adLink);
			trackAdClick(adCategory, adPositionName, adID);
		}
	}, [adOn, adID, adLink]);

	const loadData = useCallback(async () => {
		try {
			const response = await AdApi.ad(null, adCategory, {});

			if (response.status === 200) {
				const data = response.data;
				setAdID(`ad-${data.id}`);
				setAdLink(data.link);
				setAdImageUrl(data.imageUrl);
				setAdOn(true);
			}
		} catch (error) {
			console.error("Failed to load ad data", error);
		}
	}, []);

	useEffect(() => {
		loadData();
	}, [loadData]);

	return adOn ? (
		<Box
			className={`ad ${adCategory} ${adPositionName}`}
			data-id={adID}
			sx={{ width: "100%",height:200,cursor: "pointer", display:'flex', alignItems:'center', overflow:'hidden'}}
		>
			<img
				src={adImageUrl}
				alt="Advertisement Play"
				style={{ width: "100%", }}
				// onClick={handleClick}
			/>
		</Box>
	) : null;
};

export default AdMenu;
