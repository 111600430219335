import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ko_KR from "./locales/ko-KR/common.json";
import en_US from "./locales/en-US/common.json";

i18n.use(initReactI18next).init({
  lng: "ko-KR", // 기본 언어 설정
  fallbackLng: "ko-KR", // 대체 언어 설정 (기본 언어로 사용)
  resources: {
    "ko-KR": { translation: ko_KR },
    "en-US": { translation: en_US },
  },

  // template literal 방식이라고 생각하면 편하다.
  // ex: t('안녕 %{하세요}')

  // interpolation: {
  //   prefix: "%{",
  //   suffix: "}",
  // },

  returnNull: false,
  returnEmptyString: false,
  keySeparator: false,
  nsSeparator: false,
  parseMissingKeyHandler(key, defaultValue) {
    console.warn("Missing i18n key:", key, defaultValue);
    const keySeparator = "~~";
    const value = key.includes(keySeparator) ? key.split(keySeparator)[1] : key;
    return value;
  },
});

export default i18n;
