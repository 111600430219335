// ----------------------------------------------------------------------

export default function Container() {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "800px !important",
          paddingLeft: 20,
          paddingRight: 20,
          "&:-webkit-scrollbar": { display: "none !important" },
        },
      },
    },
  };
}
