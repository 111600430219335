import axios from "./axios";
import Cookie from "js-cookie";

// getSession을 interceptors에서 불러오면 build시 ssr fetch에러 발생

const request =
  (method, url, { uselessAuth = false, useFile = false } = {}) =>
  async (reqData, id, config) => {
    const _url = id ? url.split(":id").join(id) : url;
    return axios.request({
      method: method,
      url: _url,
      data: reqData ?? reqData,

      // query 배열로 보낼 경우
      paramsSerializer: (paramObj) => {
        const params = new URLSearchParams();
        for (const key in paramObj) {
          params.append(key, paramObj[key]);
        }
        return params.toString();
      },
      headers: {
        // 토큰이 필요 없을 경우
        // ...(!uselessAuth
        //   ? { authorization: "Bearer " + (await "todo::token") }
        //   : null),
        //file 업로드 할 경우
        ...(useFile ? { "Content-Type": "multipart/form-data" } : null),
        "Kiosk-Code": Cookie.get("Kiosk_ID"),
        "Accept-Language": "ko-KR", // 강제 설정
      },
      ...config,
    });
  };

export default request;

// ----------------------------------------------------------------------
