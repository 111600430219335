import { Box, Stack, Container, Button, Typography } from "@mui/material";
import Cart from "components/Cart";
import { SvgIconStyle } from "components";
import { BottomButtons } from "components/unitComponent";
import CardIcon from "assets/icons/card.svg";
import useCart from "../store";
import { useEffect } from "react";
import BackIcon from "assets/icons/back.svg";
import Api from "utils/wApi/index";
import { useTranslation } from "react-i18next";
import Header from "layout/header/index";
import { useNavigate, useLocation } from "react-router-dom";

import CartSlider from "components/CartSlider";
// ----------------------------------------------------------------------

export default function Layout({ children, noCategory }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // const [active, setActive] = useState(0);
  // const [category, setCategory] = useState([{ label: "푸드 현장예약" }]);
  // const [total, setTotal] = useState(0);

  const {
    cart,
    total,
    removeAllCart,
    removeCart,
    callCart,
    category,
    active,
    setActive,
  } = useCart((state) => state);

  useEffect(() => {
    callCart();
  }, []);

  // useEffect(() => {
  // const prices = cart.map((item) => item.paymentPrice);
  // setTotal(prices.reduce((acc, cu) => acc + cu, 0));
  // }, [cart]);

  return (
    <>
      <Box
        bgcolor="grey.100"
        sx={{
          height: "100vh",
          minHeight: "100vh",
          maxHeight: "100vh",
          maxWidth: "100vw",
          overflow: "hidden",
        }}
      >
        <Container
          sx={{
            backgroundColor: "white",
            height: "100%",
            px: `0 !important`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* header */}
          <Header />

          {/* category */}
          <Box sx={{ ml: 6, position: "relative", mt: 0.5 }}>
            <Button
              sx={{
                position: "absolute",
                left: -40,
                top: 5,
              }}
              onClick={() => {
                const [type, ...query] = location.pathname
                  .split("/")
                  .filter((v) => v !== "");
                if (type === "menus") navigate("/");
                if (type === "detail") navigate("/menus");
              }}
            >
              <SvgIconStyle
                src={BackIcon}
                sx={{ color: "black", width: 10, height: 20 }}
              />
            </Button>
            {!noCategory && (
              <Stack
                direction="row"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                {category.map((item, i) => (
                  <Button
                    key={i}
                    sx={{
                      borderRadius: 0,
                      fontSize: 24,
                      fontWeight: 700,
                      px: 2,
                      position:'relative',
                      // borderBottom:
                      //   active === item.idCategory ? "4px solid" : "",
                      // borderColor: "primary.main",
                      '&:after': active === item.idCategory ? {
                        content:'""',
                        position:'absolute',
                        bottom:0,
                        left:0,
                        width:'100%',
                        height:4,
                        bgcolor:'primary.main'} : ''

                    }}
                    onClick={() => setActive(item.idCategory)}
                  >
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: item.categoryName,
                      }}
                      sx={{fontSize:24, fontWeight: 700, strong:{fontWeight:700,display:'flex',gap:'2px'}}}
                    />
                  </Button>
                ))}
              </Stack>
            )}
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              overflowY: "scroll",
              overflowX: "hidden",
              "&:-webkit-scrollbar": { display: "none" },
            }}
          >
            {children}
          </Box>

          {/* footer */}
          <Box sx={{ width: "100%" }}>
            <CartSlider
              cart={cart}
              t={t}
              total={total}
              onDelete={(item) => removeCart(item)}
              onDeleteAll={() => removeAllCart()}
            />

            {/* <Cart
              cart={cart}
              t={t}
              total={total}
              onDelete={(item) => removeCart(item)}
              onDeleteAll={() => removeAllCart()}
            /> */}

            {/* bottom buttons*/}
            <BottomButtons
              left={{
                label: t("cancel"),
                onClick: () => {
                  const [type, ...query] = location.pathname
                    .split("/")
                    .filter((v) => v !== "");
                  if (type === "menus") navigate("/");
                  if (type === "detail") navigate("/menus");
                },
                // navigate(-1),
              }}
              right={{
                label: t("pay"),
                onClick: () => navigate("/order", { state: { history: true } }),
                icon: CardIcon,
                disabled: !cart.length,
              }}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
}
