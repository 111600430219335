// @mui

//
import { CloseIcon } from "./CustomIcons";

// ----------------------------------------------------------------------

export default function Chip(theme) {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: CloseIcon,
      },
      styleOverrides: {
        root: {
          ...theme.typography.white,
          "&:active": {
            boxShadow: "none",
          },
          fontSize: 12,
        },

        colorDefault: {
          color: theme.palette.text.secondary,
          "& .MuiChip-avatarMedium, .MuiChip-avatarSmall": {
            color: theme.palette.text.secondary,
          },
        },
        outlined: {
          borderColor: theme.palette.grey[100],
          color: theme.palette.grey[100],
          fontWeight: 400,
          maxHeight: "24px",

          "&.MuiChip-clickable": {
            "&:hover": {
              backgroundColor: "white ",
            },
          },
          "&.MuiChip-colorPrimary": {
            borderColor: theme.palette.primary.main,
          },
          "&.MuiChip-colorSecondary": {
            borderColor: theme.palette.secondary.main,
          },
        },

        filled: {
          border: "1px solid",
          borderColor: theme.palette.grey.light,
          color: theme.palette.grey[200],
          fontWeight: 400,
          maxHeight: "24px",
        },

        outlinedSecondary: {
          borderColor: theme.palette.primary.light + "!important",
          color: theme.palette.primary.light,
          borderRadius: "5px",
          height: "unset",
          fontSize: 10,
          p: 0,
          span: {
            paddingLeft: "6px",
            paddingRight: "6px",
          },
        },
        avatar: {
          fontSize: theme.typography.subtitle2.fontSize,
          fontWeight: theme.typography.subtitle2.fontWeight,
        },
        avatarColorInfo: {
          color: theme.palette.info.contrastText,
          backgroundColor: theme.palette.info.dark,
        },
        avatarColorSuccess: {
          color: theme.palette.success.contrastText,
          backgroundColor: theme.palette.success.dark,
        },
        avatarColorWarning: {
          color: theme.palette.warning.contrastText,
          backgroundColor: theme.palette.warning.dark,
        },
        avatarColorError: {
          color: theme.palette.error.contrastText,
          backgroundColor: theme.palette.error.dark,
        },
      },
    },
  };
}
