import {Box, Button, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import NaverPayIcon from "assets/icons/naver-pay.svg";
import AliPayIcon from "assets/icons/alipay.svg";
import WechatPayIcon from "assets/icons/wechatpay.svg";
import {SvgIconStyle} from "../index";

export default function PayStep22({onClickNaverPay, onClickAliPay, onClickWechatPay}) {
    const {t} = useTranslation();
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h4">간편결제</Typography>
            <Typography variant="body1">결제 수단을 선택해주세요.</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
            <Button onClick={onClickNaverPay} sx={{py: 7}} variant="outlined" color="inherit" fullWidth
                    style={{height: '100%', background: "#00DE5A"}}>
                <div>
                    <Box>
                        <SvgIconStyle
                            src={NaverPayIcon}
                            origin
                            sx={{height: '45px', width: '240px', mb: 1}}
                        />
                    </Box>
                    <Typography variant="h5">네이버 페이</Typography>
                </div>
            </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
            <Button onClick={onClickAliPay} sx={{py: 7}} variant="outlined" color="inherit" fullWidth
                    style={{height: '100%', background: "#1677FF"}}>
                <div>
                    <Box>
                        <SvgIconStyle
                            src={AliPayIcon}
                            origin
                            sx={{height: '45px', width: '240px', mb: 1}}
                        />
                    </Box>
                    <Typography variant="h5">Alipay</Typography>
                </div>
            </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
            <Button onClick={onClickWechatPay} sx={{py: 7}} variant="outlined" color="inherit" fullWidth
                    style={{height: '100%', background: "#22AC38"}}>
                <div>
                    <Box>
                        <SvgIconStyle
                            src={WechatPayIcon}
                            origin
                            sx={{height: '45px', width: '240px', mb: 1}}
                        />
                    </Box>
                    <Typography variant="h5">WeChat Pay</Typography>
                </div>
            </Button>
        </Grid>
    </Grid>

}