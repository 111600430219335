import Modal from "@mui/material/Modal";
import { Box, Button, Stack } from "@mui/material";

export default function CommonModal({
  children,
  footer,
  subFooter,
  sx,
  width,
  ...other
}) {
  return (
    <Modal
      sx={{
        root: {
          bottom: "0px",
          zIndex: "2000",
        },
        ".MuiBackdrop-root": {
          background: "rgba(0,0,0,0.8)",
        },
        ".MuiBox-root": {
          outline: "none",
        },
        ...sx,
      }}
      {...other}
    >
      <Box
        sx={{
          height: "100%",
          position: "relative",
          maxWidth: width ? width : "480px",
          minWidth: width ? width : "480px",
          mx: "auto",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            transform: "translate(0,-50%)",
            top: "50%",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "16px",
            }}
          >
            <Box p={3}>{children}</Box>
            {footer ? (
              <Stack
                direction="row"
                sx={{ borderTop: "1px solid", borderColor: "grey.400" }}
              >
                {subFooter ? (
                  <Button
                    fullWidth
                    sx={{
                      fontWeight: 400,
                      fontSize: 20,
                      color: "grey.middle",
                      borderRight: "1px solid",
                      borderRadius: 0,
                      borderColor: "grey.400",
                      py: 1.4,
                      ...subFooter?.sx,
                    }}
                    onClick={subFooter?.onClick}
                    disabled={subFooter.disabled}
                  >
                    {subFooter.label}
                  </Button>
                ) : null}
                <Button
                  fullWidth
                  sx={{ py: 1.4, fontSize: 20, ...footer?.sx }}
                  onClick={footer.onClick}
                  disabled={footer.disabled}
                >
                  {footer.label}
                </Button>
              </Stack>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
