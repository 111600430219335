// import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
        // IconComponent: InputSelectIcon,
      },
      styleOverrides: {
        select: {
          fontSize: 14,
          fontWeight: 500,
          borderBottom: `solid 1px ${theme.palette.grey[400]}`,
        },
        icon: {
          color: theme.palette.grey.dark,
        },
      },
    },
  };
}
