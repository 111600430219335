import Api from "utils/wApi/index";

// 일반:: 단일 상품 담기
export async function addNormal({ id, buyCount, price }) {
	const body = [
		{
			normal: {
				id,
				buyCount,
				optionMode: "NOT",
			},
			buyCount,
			price,
		},
	];
	try {
		await Api.addNormal(body);
	} catch (error) {
		alert(error.message);
	}
}
// 일반:: 옵션||카테고리옵션 상품 담기
export async function addNormalOptions({
	id,
	buyCount,
	price,
	optionId,
	optionMode,
}) {
	// 일반상품 카테고리 옵션(필수, 선택) 일 경우
	let optionArr = [];

  if ((optionMode === 'TOP' || optionMode === 'SELECT') &&optionId?.length > 0) {
      optionArr = optionId.map((x) => {
        return { id: x.id, buyCount };
      });
    
  }

	const body = [
		{
			normal: {
				id,
				buyCount,
				optionMode,
        productDTOs:
        optionMode === 'TOP' || optionMode === 'SELECT' ? optionArr :[
					{
						id: optionId,
						buyCount,
					},
				],
			},
			buyCount,
			price,
		},
	];
	try {
		await Api.addNormal(body);
	} catch (error) {
		alert(error.message);
	}
}

// 시간 단일 상품 담기
export async function addTime({ id, buyCount, price }) {
	const body = [
		{
			menuTime: {
				id,
				buyCount,
			},
			price,
		},
	];
	try {
		await Api.addTimeMain(body);
	} catch (error) {
		alert(error.message);
	}
}

// 시간 옵션 상품 담기
export async function addTimeOption({ id, buyCount, price, optionId }) {
	const body = [
		{
			menuTime: {
				id,
				menuTypes: [
					{
						id: optionId,
						buyCount,
					},
				],
			},
			price,
		},
	];

	try {
		await Api.addTimeType(body);
	} catch (error) {
		alert(error.message);
	}
}
