// import { useState, useEffect } from "react";

// // ----------------------------------------------------------------------

// export default function useCountdown(date) {
//   const [countdown, setCountdown] = useState({
//     days: "00",
//     hours: "00",
//     minutes: "00",
//     seconds: "00",
//   });

//   useEffect(() => {
//     const interval = setInterval(() => setNewTime(), 1000);
//     return () => clearInterval(interval);
//   }, [countdown]);

//   const setNewTime = () => {
//     const startTime = date;
//     const endTime = new Date();
//     const distanceToNow = startTime.valueOf() - endTime.valueOf();

//     const getDays = Math.floor(distanceToNow / (1000 * 60 * 60 * 24));

//     const getHours = `0${Math.floor(
//       (distanceToNow % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
//     )}`.slice(-2);

//     const getMinutes = `0${Math.floor(
//       (distanceToNow % (1000 * 60 * 60)) / (1000 * 60)
//     )}`.slice(-2);

//     const getSeconds = `0${Math.floor(
//       (distanceToNow % (1000 * 60)) / 1000
//     )}`.slice(-2);

//     setCountdown({
//       days: getDays.toString() || "000",
//       hours: getHours || "00",
//       minutes: getMinutes || "00",
//       seconds: getSeconds || "00",
//     });
//   };

//   return countdown
// }

// // Usage
// // const countdown = useCountdown(new Date('07/07/2022 21:30'));

import { useState, useEffect } from "react";
import useCart from "store";

export default function useCountdown(initialDate) {
  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const [endTime, setEndTime] = useState(initialDate);

  useEffect(() => {
    const interval = setInterval(() => setNewTime(), 1000);
    return () => clearInterval(interval);
  }, [countdown]);

  const setNewTime = () => {
    const currentTime = new Date();
    const distanceToNow = endTime.valueOf() - currentTime.valueOf();

    if (distanceToNow <= 0) {
      const interval = setInterval(() => setNewTime(), 1000);
      clearInterval(interval);
      return;
    }

    const getDays = Math.floor(distanceToNow / (1000 * 60 * 60 * 24));
    const getHours = `0${Math.floor(
      (distanceToNow % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )}`.slice(-2);
    const getMinutes = `0${Math.floor(
      (distanceToNow % (1000 * 60 * 60)) / (1000 * 60)
    )}`.slice(-2);
    const getSeconds = `0${Math.floor(
      (distanceToNow % (1000 * 60)) / 1000
    )}`.slice(-2);

    setCountdown({
      days: getDays.toString() || "000",
      hours: getHours || "00",
      minutes: getMinutes || "00",
      seconds: getSeconds || "00",
    });
  };

  // 카운트다운 연장을 위한 함수
  const extendCountdown = (additionalMinutes) => {
    const newEndTime = new Date(endTime);
    newEndTime.setMinutes(newEndTime.getMinutes() + additionalMinutes);

    setEndTime(newEndTime);
  };

  return { countdown, extendCountdown };
}
