import { Grid, Container, Typography, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import Api from "utils/wApi/index";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Logo, CommonModal } from "components";
import AdMenu from "../components/ad/ad-menu";

import useCart from "../store";
import Cookie from "js-cookie";

// ----------------------------------------------------------------------

export default function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    festival,
    cart,
    category,
    setCategory,
    setActive,
    setFestival,
    removeAllCart,
    setCountdown,
  } = useCart((state) => state);

  // 카테고리 없을시 모달 상태
  const [open, setOpen] = useState(false);


  async function init() {
    console.log("Kiosk_ID: ", Cookie.get("Kiosk_ID"));

    if (!Cookie.get("Kiosk_ID")) return;
    Cookie.set("qx-extended", "");

    try {
      const {
        data: { id, name },
      } = await Api.festival();
      const { data } = await Api.getCategory(null, id);
      setFestival(name);
      setCategory(data);
    } catch (error) {
      // 안내 모달창 열기
      setOpen(true)
    }
    if (!!cart.length) removeAllCart();
  }
  useEffect(() => {
    init();
  }, []);
  return (
    <Container title="home" sx={{px:'0 !important',display:"flex",flexDirection: "column",justifyContent:"space-between",height:'100vh',overflow:'hidden'
    }}>
      <Box sx={{px:3}}>
      <Box sx={{ px: 2, py: 3 ,display:'flex',alignItems:'center',height:92}}>
        <Logo />
      </Box>

      <Container>
        <Typography variant="h3" paragraph fontWeight={400} mb={1} mt={2}>
          {/* 반갑습니다 */}
          {t("hello")}
        </Typography>
        <Typography
          variant="h3"
          paragraph
          fontWeight={700}
          color="primary.main"
          mb={3}
        >
          {/* 상품을 선택해주세요 */}
          {t("select-item")}
        </Typography>

        {/* 상품 카테고리 */}
        <Grid spacing={3} container>
          {category?.map((item, i) => (
            <Grid item xs={4} key={i}>
              <Box
                sx={{
                  backgroundColor: "grey.400",
                  width: "100%",
                  paddingBottom: "100%",
                  borderRadius: 1,
                  cursor: "pointer",
                  background: `center/contain no-repeat url(${item.kioskIcon})`,
                }}
                onClick={() => {
                  setActive(item.idCategory);
                  navigate(`/menus`);
                  setCountdown(5);
                }}
              />
              <Typography
                fontSize={20}
                fontWeight={600}
                dangerouslySetInnerHTML={{ __html: item.categoryName }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* ----------- 연결된 카테고리가 없을 경우 ----------- */}
      <CommonModal
        open={open}
        footer={{ label: "닫기", onClick: () => setOpen(false) }}
      >
        <Box sx={{ textAlign: "center", py: 2 }}>
          <Typography variant="h3" fontSize={24} mb={2}>
            {'알림'}
          </Typography>
          <Typography fontSize={20} color="grey.middle">
            {'선택 가능한 카테고리가 없습니다.'}
          </Typography>
        </Box>
      </CommonModal>
      {/* ----------- 연결된 카테고리가 없을 경우 ----------- */}

      </Box>
      
      <Box>

{/* 광고 모달 : ad_4 */}
<AdMenu />
{/* 광고 모달 : ad_4 */}
</Box>

    </Container>
  );
}
