import React from "react";
//icons
import PlusIcon from "assets/icons/plus.svg";
import MinusIcon from "assets/icons/minus.svg";
//mui
import { Typography, Stack, Button, Box } from "@mui/material";
//components
import { SvgIconStyle } from "components";

import useCart from "../store";
import { useTranslation } from "react-i18next";

export default function CommonCounter({
  count,
  setCount,
  item,
  max,
  isCart,
  sx,
}) {
  const { cart, removeCart, increaseCart, decreaseCart } = useCart(
    (state) => state
  );

  const { t } = useTranslation();
  const onIncrease = () => {
    if (isCart) {
      // 장바구니
      if (!max) {
        return null;
      } else {
        return item.buyCount >= max ? alert(t("over-max")) : increaseCart(item);
      }
    } else {
      // 상품 상세
      if (!max) {
        return null;
      } else {
        count >= max ? null : setCount(count + 1);
      }
    }
  };
  const onDecrease = () => {
    setCount ? setCount(count <= 0 ? 0 : count - 1) : null;
    if (isCart) {
      if (count - 1 == 0) return removeCart(item);
      decreaseCart(item);
    }
  };

  const increaseDisabled = () => {
    const limit = item.salesLimitOnce
      ? item.qty
      : Math.min(...[item.qty, item.salesLimitOnce]);
    return !!limit && item.buyCount == limit;
  };
  
  return (
    <Box sx={{ ...sx }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          border: "1px solid",
          borderColor: "grey.400",
          borderRadius: 0.5,
          display: "inline-flex",
        }}
      >
        <Button
          onClick={onDecrease}
          disabled={count === 0}
          sx={{
            borderRight: "1px solid",
            bgcolor: "grey.light",
            borderColor: "grey.400",
            borderRadius: "4px 0px 0px 4px",
            px: 1.5,
            "&:focus,&:hover": {
              backgroundColor: "grey.light",
              borderRadius: "4px 0px 0px 4px ",
            },
            "&.Mui-disabled": {
              span: {
                opacity: 0.3,
              },
            },
          }}
        >
          <SvgIconStyle src={MinusIcon} size={20} sx={{ color: "grey.dark" }} />
        </Button>

        <Box variant="text" sx={{ py: 0.5, px: 3 }}>
          <Typography variant="caption" color={"grey.dark"} fontSize={20}>
            {count}
          </Typography>
        </Box>

        <Button
          onClick={onIncrease}
          disabled={isCart ? increaseDisabled() : !max || count >= max}
          sx={{
            borderLeft: "1px solid",
            bgcolor: "grey.light",
            borderColor: "grey.400",
            borderRadius: "0px 4px 4px 0px ",
            px: 1.5,
            "&:focus,&:hover": {
              backgroundColor: "grey.light",
              borderRadius: "0px 4px 4px 0px ",
            },
            "&.Mui-disabled": {
              span: {
                opacity: 0.3,
              },
            },
          }}
        >
          <SvgIconStyle src={PlusIcon} size={20} sx={{ color: "grey.dark" }} />
        </Button>
      </Stack>
    </Box>
  );
}
