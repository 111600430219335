// ----------------------------------------------------------------------

export default function Typography(theme) {
  return {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: "p",
        },
      },

      styleOverrides: {
        root: {
          marginTop: "0",
        },
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
