import { memo } from "react";
import { useNavigate } from "react-router-dom";

import { backdropClasses, Box } from "@mui/material";
import SvgIconStyle from "./SvgIconStyle";
import LogoIcon from "assets/icons/logo.svg";

// ----------------------------------------------------------------------

function Logo({ onDark = false, isSimple = false, sx }) {
  const navigate = useNavigate();

  return (
      <Box
        sx={{
          lineHeight: 0,
          cursor: "pointer",
          display: "inline-flex",
          ...sx,
        }}
      onClick={() => 
        navigate(`/?code=${localStorage.getItem("Kiosk_ID")}`)
          // window.location.href = `${
          //   window.location.origin
          // }?code=${localStorage.getItem("Kiosk_ID")}`;
        }
      >
        <SvgIconStyle
          src={LogoIcon}
          origin
          sx={{ width: 125, minWidth: 125, maxWidth: 125, height: 26,}}
        />
      </Box>
  );
}

export default memo(Logo);
