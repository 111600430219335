import { useState, useEffect } from "react";
import { Grid, Typography, Box, Button, CircularProgress } from "@mui/material";
import Api from "utils/wApi/index";
import { fDate } from "utils/formatTime";
import { selectButtonStyle } from "utils/uiData";
import useCart from "store";
import { useTranslation } from "react-i18next";

export default function SelectTime({
	selected,
	time,
	setTime,
	callList,
	setMaxCount,
}) {
	const [loading, setLoading] = useState(false);
	const [selectedDate, setSelectedDate] = useState(null);
	const [timeList, setTimeList] = useState([]); //전체 시간 리스트
	const [category, setCategory] = useState([]);
	const [selectedTimeCategory, setSelectedTimeCategory] = useState(null); //선택된 카테고리
	const [formattedTime, setFormattedTime] = useState([]); //카테고리에 속한 시간 리스트
	const { cart } = useCart((state) => state);
	const { t } = useTranslation();

	const [todayTemp, setTodayTemp] = useState();

	async function init() {
		setLoading(true);

		try {
			if (!selected) return;
			if (selected.mode !== "TIME") return;
			// 싱품 수령 가능한 날짜 호출
			const {
				data: { data: dateList },
			} = await Api.getMenuDates(null, selected.id);

			// todo::당일 상품 테스트 해볼것
			const today =
				dateList.find(
					(item) =>
						fDate(new Date(item.menuTimeDate), "yyyy-MM-dd") ==
						fDate(new Date(), "yyyy-MM-dd")
				)?.menuTimeDate || dateList[0].menuTimeDate;
			setSelectedDate(today);

			// today::remove
			setTodayTemp(fDate(new Date(today), "yyyy-MM-dd"));

			// 싱품 수령 날짜별 시간 호출
			const {
				data: { data: timeList },
			} = await Api.getMenuTimes(null, selected.id, {
				params: { date: fDate(today, "yyyy-MM-dd") },
			});

			setTimeList(timeList);
			setCategory([
				...new Set(timeList.map((item) => fDate(item.time, "HH:00"))),
			]);
		} catch (error) {
			// TODO : 픽업시간 없을 경우 - 모달
			// alert(error?.message || "");
		}
		setLoading(false);
	}
	useEffect(() => {
		init();
	}, [selected]);

	// 장바구니 담은 후 레프레시
	useEffect(() => {
		if (!callList) return;
		async function getTimeList() {
			const {
				data: { data: timeList },
			} = await Api.getMenuTimes(null, selected.id, {
				params: { date: fDate(selectedDate, "yyyy-MM-dd") },
			});
			setTimeList(timeList);
			const newArr = timeList.filter(
				(item) => fDate(item.time, "HH:00") == selectedTimeCategory
			);
			setFormattedTime(newArr);
			setTime(time);
		}
		getTimeList();
	}, [cart]);

	useEffect(() => {
		const newArr = timeList.filter(
			(item) => fDate(item.time, "HH:00") == selectedTimeCategory
		);

		setFormattedTime(newArr);

		if (newArr.length > 0) {
			let filterArr = newArr.filter((x) => x.maxQty !== 0 && x);

			setTime(filterArr[0]);
		}
	}, [selectedTimeCategory]);

	useEffect(() => {
		setSelectedTimeCategory(category[0]);
	}, [category]);

	return (
		<Box>
			{loading ? (
				<Box sx={{ display: "flex", justifyContent: "center", py: 5 }}>
					<CircularProgress />
				</Box>
			) : (
				<Box>
					<Typography fontSize={18} fontWeight={500} color="grey.middle" mb={1}>
						{/* 픽업 시간을 선택하세요 */}
						{t("pickup-time")}
					</Typography>
					{/* 시간 카테고리=================== */}
					<Grid container spacing={2}>
						{!!category.length &&
							category?.map((item, i) => {
								return (
									<Grid item xs={1.71} key={i}>
										<Button
											fullWidth
											variant={
												selectedTimeCategory === item ? "contained" : "outlined"
											}
											sx={{
												...selectButtonStyle(selectedTimeCategory == item),
											}}
											onClick={() => setSelectedTimeCategory(item)}
										>
											{item}
										</Button>
									</Grid>
								);
							})}
					</Grid>
					{/* 시간 리스트====================== */}
					<Grid container spacing={2} mt={2}>
						{formattedTime.map((item, i) => {
							console.log(time, item?.id, time?.active, item?.maxQty);

							return (
								<Grid item xs={2} key={i}>
									<Button
										fullWidth
										variant={
											time?.id === item?.id ||
											!time?.active ||
											item?.maxQty === 0
												? "contained"
												: "outlined"
										}
										sx={{
											...selectButtonStyle(time?.id == item.id),
											flexDirection: "column",
										}}
										onClick={() => {
											setMaxCount(item.maxQty) & setTime(item);
										}}
										disabled={!time?.active || item?.maxQty === 0}
									>
										<Typography variant="h6">
											{fDate(item.time, "HH:mm")}
										</Typography>

										{/* {item.maxQty}개 */}
										{selected?.maxQtyView && (
											<Typography
												color={
													time?.id === item?.id ||
													!time?.active ||
													item?.maxQty === 0
														? "white"
														: "black"
												}
											>
												{item.maxQty === 0
													? t("sold-out")
													: t("unit", { count: item.maxQty })}
											</Typography>
										)}
									</Button>
								</Grid>
							);
						})}
					</Grid>
				</Box>
			)}
		</Box>
	);
}
