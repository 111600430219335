export function trackAdImpression(adCategory, adPositionName, adID) {
    if (typeof window !== 'undefined') {
        window.setTimeout(() => {
            console.log(
                {
                    ad_category: adCategory,
                    ad_position_name: adPositionName,
                    ad_ID: adID,
                },
                'trackAdImpression'
            );
            if (window.gtag) {
                window.gtag('event', 'ad_impression', {
                    ad_category: adCategory,
                    ad_position_name: adPositionName,
                    ad_ID: adID,
                });
            }
        }, 500);
    }
}

export function trackAdClick(adCategory, adPositionName, adID) {
    if (typeof window !== 'undefined' && window.gtag) {
        console.log(
            {
                ad_category: adCategory,
                ad_position_name: adPositionName,
                ad_ID: adID,
            },
            'trackAdClick'
        );
        window.gtag('event', 'ad_click', {
            ad_category: adCategory,
            ad_position_name: adPositionName,
            ad_ID: adID,
        });
    }
}

export function openUrlByApp(url) {
    if (window.appInfo && window.appInfo.os) {
        try {
            if (window.appInfo.os === 'iOS') {
                window.webkit.messageHandlers.openUrl.postMessage(url);
                return;
            } else if (window.appInfo.os === 'Android' && window.AppInfo) {
                window.AppInfo.openUrl(url);
                return;
            }
        } catch (e) {
            console.error(e);
        }
    }
    window.open(url, '_blank');
}
