export const fadeStyle = {
  mt: 3,
  backgroundColor: "white",
  position: "absolute",
  zIndex: 1000,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  ">div": {
    flexGrow: 1,
    height: "100%",
  },
};

export function selectButtonStyle(selected, light) {
  return {
    py: 1,
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: 18,
    color: selected ? "white" : "black",

    borderColor: selected ? "primary.main" : "grey.300",
    "&:hover,&:active": {
      backgroundColor: light ? "primary.light" : "primary.main",
    },
  };
}

export function inputStyle(error) {
  const borderColor = error ? "error.main" : "grey.300";
  return {
    marginTop: "0px !important",
    marginBottom: "20px",
    maxHeight: 44,
    ".MuiInputBase-root": {
      "&:focus-visible": {
        outline: "none",
      },
      "&:hover": {
        borderColor,
      },
      input: {
        fontSize: 14,
        padding: "14px",
        "&[type='number']": {
          "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
        },
      },
      ".MuiOutlinedInput-notchedOutline": {
        padding: "14px",
      },

      ".MuiOutlinedInput-notchedOutline": {
        fontSize: 14,
        borderColor,
        height: "52px",
      },
      "&.Mui-focused": {
        borderColor,
        ".MuiOutlinedInput-notchedOutline": {
          borderColor,
          borderWidth: "1px !important",
        },
      },
    },
  };
}

export const EmailInputStyle = {
  maxHeight: 48,
  ".MuiSelect-select": {
    minHeight: "unset",
    maxHeight: "48px",
    borderBottom: "0 !important",
  },
  "&.Mui-focused": {
    fieldset: {
      "&.MuiOutlinedInput-notchedOutline": {
        border: "1px solid",
        borderColor: "grey.300",
      },
    },
  },
};

export const EmailSelectInputStyle = {
  position: "absolute",
  top: 0,
  width: "80%",
  overflow: "hidden",
  maxHeight: "46px",
  margin: "1px 0 0 1px !important",
  backgroundColor: "transparent",
  borderRadius: "7px 0 0 7px !important",
  ".MuiInputBase-root": {
    maxHeight: "unset",
    input: {
      fontSize: 14,
      padding: "10px 0 10px 14px",
      height: "28px",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.Mui-focused": {
      ".MuiOutlinedInput-notchedOutline": {
        border: 0,
      },
    },
  },
};

export const keyboardLayout = {
  numeric: ["1 2 3", "4 5 6", "7 8 9", "010 0 {bksp}"],
  default: [
    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{tab} q w e r t y u i o p [ ] \\",
    "{lock} a s d f g h j k l ; ' {enter}",
    "{shift} z x c v b n m , . / {shift}",
    ".com @ {space}",
  ],
  shift: [
    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
    "{tab} Q W E R T Y U I O P { } |",
    '{lock} A S D F G H J K L : " {enter}',
    "{shift} Z X C V B N M < > ? {shift}",
    ".com @ {space}",
  ],
};

export const temp = {
  id: 2454,
  name: "[SEOUL] TEAM GREEN 25일(일)",
  subtitle: "페스티벌/티켓",
  images: [],
  summary:
    '<strong><span style="color:black">One and Only No.1 SUMMER FESTIVAL, WATERBOMB<br>\r\n돌아올 뜨거운 여름을 위해 티켓을 미리 준비하세요!</span></strong><br>\r\n<br>\r\n본 상품은 2023년 6월 25일(일)<br><strong><span style="color:black"> <워터밤 서울> </span></strong>\r\n<strong><span style="color:green">TEAM GREEN\r\n</span></strong> 7차 티켓 상품입니다. <BR>\r\n<br>\r\n<strong><span style="color:red">상품 설명 및 상품 고시를 꼭 확인해주세요!</span></strong>\r\n',
  thumbnail:
    "https://s3.ap-northeast-2.amazonaws.com/queens-smile/images/aart_4d19c8c5fab1469187c1938d56024818.png",
  price: 154000,
  fixedPrice: 154000,
  dcRate: null,
  active: true,
  business: {
    id: 300,
    name: "WATERBOMB SEOUL 2023 TICKET",
    image: null,
    categories: null,
    introduce:
      '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p><p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p><p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
    address: "",
    phone: "",
    email: "",
    website: "",
    active: true,
  },
  receipt: null,
  information: null,
  notice: null,
  qty: 247,
  salesLimit: 4,
  prefix: "SWSG7",
  qrcode: null,
  pickup: null,
  priceView: true,
  maxQtyView: true,
  idBranch: 66,
  idCategory: null,
  menuTerm:
    '<strong><span style="color:red">장바구니 담기 이후<br>15분동안 결제하지 않으면 <br>장바구니 목록에서 자동으로 삭제됩니다.<br></span></strong><br>확인 버튼을 클릭하기 전 상품 설명과 상품 고지사항을 꼭 확인해주시기 바랍니다.<br>\r\n<br>\r\n해당 상품은<br> <strong>WATERBOMB SEOUL</strong>티켓 상품으로<br>\r\n<strong><span style="color:blue">2023년 6월 22일 23시 59분까지 자유로운 취소가 가능하며, 100% 환불됩니다.</span></strong><br>\r\n<br>\r\n<strong><span style="color:black">상품 설명 및 상품 고지사항<br>미숙지로 인하여 발생한 모든 문제는<br>\r\n행사 주최사 및 <br>운영사, 구매처는 일절 책임이 없으며<br>\r\n모든 책임은<br>전적으로 구매 당사자에게 있습니다.',
  mode: "NORMAL",
  seo: null,
  time: null,
  normal: {
    normalMode: "RECEIPT",
    optionMode: "NOT",
    seatImage: null,
    adult: false,
    labelByTopOptions: null,
    topOptions: [],
    options: [],
    numberOfSales: 0,
    totalSales: 103,
    qty: 247,
    soldOut: false,
    dateMode: false,
  },
  number: null,
  branchImage1: null,
  branchImage2: null,
  branchImage3: null,
  links: [],
};
