import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { SvgIconStyle } from "components";
import CardInfoUrl from "assets/image/pay-card-info.png";
import { useTranslation } from "react-i18next";

export default function PayStep21({}) {
  const { t } = useTranslation();
  return (
    <Box sx={{ px: 4 }}>
      <Box
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: 400,
            mt:'130px', mb:'60px'
          }}
        >
          <img
            src={CardInfoUrl}
            alt="Insert your card with the chip side up."
            width={'100%'}
          />
        </Box>
         <Typography variant="h2" fontSize={30} textAlign="center" mt={3}>
          {/* 결제를 위해 카드를 삽입하거나
          <br /> 삼성페이/애플페이/QR코드를 이용해주세요. */}
          {/* {t("pay-methods")} */}
          {t("insert-ic")}
        </Typography>
      </Box>
    </Box>
  );
}
