

import { Grid, Container, Typography, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import ReceiveTagValue from 'utils/NFC'

// ----------------------------------------------------------------------

export default function EntrancePage() {
  useEffect(() => {
    // ReceiveTagValue()
  }, []);
    
  return (
    <Container>
     
    </Container>
  );
}
