import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// components
import Layout from "layout/index";
import {
	Box,
	Container,
	Stack,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
} from "@mui/material";
import { BottomButtons } from "components/unitComponent";

// translations
import { useTranslation } from "react-i18next";

import useCart from "../store";
import Api from "utils/wApi/index";
import PayStep1 from "../components/pay/pay-step1";
import PayStep21 from "../components/pay/pay-step2-1";
import PayStep22 from "../components/pay/pay-step2-2";
import Cookie from "js-cookie";
import AlertDialog from "../components/pay/alert-dialog";
import NaverPayIcon from "../assets/icons/naverpay-primary.svg";
import AliPayIcon from "../assets/icons/alipay-primary.svg";
import WeChatPayIcon from "../assets/icons/wechat-primary.svg";

export default function Pay() {
	const [status, setStatus] = useState("PAY"); // 1.SELECT 2-1.PAY 2-2.SIMPLE_PAY
	const { festival, cart, total, addCart, decreaseCart, removeCart } = useCart(
		(state) => state
	);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { orderId } = location.state;
	const [alertWaiting, setAlertWaiting] = useState(false);
	const [payType, setPayType] = useState("");
	const [alertTitle, setAlertTitle] = useState("");
	const [alertIcon, setAlertIcon] = useState();

	// 결제실패 여부
	const [payState, setPayState] = useState(false);
	const [payStateDesc, setPayStateDesc] = useState("");

	// 키오스크 외 기기로 결제 시도
	const payNotKiosk = () => {
		setPayState(true);
		setPayStateDesc(`키오스크로 결제해주세요.`);
	};

	const handlePayStep = () => {
		if (status === "SELECT") {
			setStatus("PAY");
		} else {
			setStatus("SELECT");
		}
	};

	const handleSimplePayStep = () => {
		if (status === "SELECT") {
			setStatus("SIMPLE_PAY");
		} else {
			setStatus("SELECT");
		}
	};

	const handleNaverPay = () => {
		if (window.ChromeAPI === undefined)
			// return alert(`ChromeAPI.toPayment(${total}, "BARCODE") 호출 : 키오스크 브라우저로 실행해야함`);
			payNotKiosk();
		else {
			setAlertWaiting(true);
			setPayType("NAVER");
			window.ChromeAPI.toPayment(`${total}`, "BARCODE");
		}
	};

	const handleAliPay = () => {
		if (window.ChromeAPI === undefined)
			// return alert(`ChromeAPI.toPayment(${total}, "BARCODE") 호출 : 키오스크 브라우저로 실행해야함`);
			payNotKiosk();
		else {
			setAlertWaiting(true);
			setPayType("ALIPAY");
			window.ChromeAPI.toPayment(`${total}`, "BARCODE");
		}
	};

	const handleWechatPay = () => {
		if (window.ChromeAPI === undefined)
			// return alert(`ChromeAPI.toPayment(${total}, "BARCODE") 호출 : 키오스크 브라우저로 실행해야함`);
			payNotKiosk();
		else {
			setAlertWaiting(true);
			setPayType("WECHAT");
			window.ChromeAPI.toPayment(`${total}`, "BARCODE");
		}
	};

	// 1.키오스크 결제 요청 
	// 240925 : 간편결제 결제취소 불가하기때문에 제거, 카드결제 방식으로만 진행
	useEffect(() => {
		onPay();
	},[])
	// useEffect(() => {
	// 	if (status === "PAY") {
	// 		onPay();
	// 	}
	// }, [status]);

	useEffect(() => {
		if (payType) {
			if (payType === "NAVER") {
				setAlertTitle("네이버페이 결제");
				setAlertIcon(NaverPayIcon);
			} else if (payType === "ALIPAY") {
				setAlertTitle("Alipay 결제");
				setAlertIcon(AliPayIcon);
			} else if (payType === "WECHAT") {
				setAlertTitle("WeChat Pay 결제");
				setAlertIcon(WeChatPayIcon);
			}
		}
	}, [alertWaiting, payType]);

	function onPay() {
		// 키오스크 외 기기로 실행시
		if (window.ChromeAPI === undefined) {
			// return alert("ChromeAPI.toPayment 호출 : 키오스크 브라우저로 실행해야함");
			payNotKiosk();
		} else {
			window.ChromeAPI.toPayment(`${total}`, "CARD");
		}
	}

	// 2.결제 callback
	useEffect(() => {
		if (typeof window === "undefined") return;
		window.toPaymentResult = function (res, data) {
			if (res !== "PaymentSuccess") {
				// 결제실패 알람
				// alert("결제실패");
				// return navigate("/info");
				setAlertWaiting(false);

				setPayState(true);
				// setPayStateDesc(`결제 실패하였습니다. \n 다시 시도해주세요`);
				setPayStateDesc(`onPay :: ${error.message}`);
			} else {
				setPaymentComplete(res, data);
				setAlertWaiting(false);
			}
		};
	}, [window]);

	// 3.결제 결과 & 후 처리
	async function setPaymentComplete(res, data) {
		// if (res == "PaymentCancel") return alert("결제취소") & navigate("/info");
		// if (res == "PaymentFail") return alert("결제실패") & navigate("/info");

		const [result, cardNum, cardName, quot, tid, date] = data.split("&");
		const formattedDate = date.replace(
			/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/,
			"20$1-$2-$3 $4:$5:$6"
		);

		try {
			const { data } = await Api.complete({ orderId, tid });

			const orderData = {
				title: festival,
				date: formattedDate,
				price: `${(+data.amount).toLocaleString()}`,
				cardName: cardName,
				cardNo: cardNum,
				appNo: tid + ` (${Cookie.get("Kiosk_ID")})`,
				items: JSON.stringify(
					data.items.map((item) => {
						return {
							name: item.name,
							amount: `${item.qty}`, //수량
							price: `${+(item.price / item.qty).toLocaleString()}`, //단가
							total: `${+item.price.toLocaleString()}`, //가격
						};
					})
				),
				// items: JSON.stringify(
				//   cart.flatMap((item) => {
				//     const nameTime = item.orderTime
				//       ? fDate(item.orderTime, "HH:mm") + ` - `
				//       : "";
				//     const names = item.description.split(" - ");

				//     if (names.length > 1) {
				//       const [name, ...subNames] = names;
				//       return [
				//         { name: `${nameTime}${name}` },
				//         ...subNames.map((subName) => ({
				//           name: `ㄴ(옵션)${subName}`,
				//           amount: `${item.buyCount}`,
				//           price: `${+(
				//             item.paymentPrice / item.buyCount
				//           ).toLocaleString()}`,
				//           total: `${+item.paymentPrice.toLocaleString()}`,
				//         })),
				//       ];
				//     }
				//     return [
				//       {
				//         name: `${nameTime}${item.description}`,
				//         amount: `${item.buyCount}`,
				//         price: `${+(
				//           item.paymentPrice / item.buyCount
				//         ).toLocaleString()}`,
				//         total: `${+item.paymentPrice.toLocaleString()}`,
				//       },
				//     ];
				//   })
				// ),
			};
			navigate("/complete", {
				state: { orderData },
			});
		} catch (error) {
			console.log(`결제api-error::${error.message}`);
			setPayState(true);
			// setPayStateDesc(`결제 실패하였습니다. \n 다시 시도해주세요`);
			setPayStateDesc(`setPaymentComplete :: ${error.message}`);
			// alert(`결제api-error::${error.message}`);
		}
		setStatus("SELECT");
	}

	return (
		<Layout
			sx={{
				px: "0 !important",
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			{/* <Container sx={{flexGrow: 1}}>
               <Box sx={{px: 4}}>
                   <Box sx={{px: 10, mt: 8}}>
                       <Box
                            sx={{
                                paddingBottom: "60%",
                             backgroundColor: "grey.600",
                                position: "relative",
                            }}
                        >
                            <SvgIconStyle
                                src={SymbolIcon}
                                origin
                                sx={{
                                    width: "200px",
                                    height: "150px",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%,-50%)",
                                    opacity: 0.4,
                                }}
                            />
                        </Box>
                        <Typography variant="h2" fontSize={20} textAlign="center" mt={3}>
                             결제를 위해 카드를 삽입하거나
                            <br /> 삼성페이/애플페이/QR코드를 이용해주세요. 
                            {t("pay-methods")}
                        </Typography>
                    </Box>
                </Box>
            </Container> */}
			<Container sx={{ flexGrow: 1 }}>
				{status === "SELECT" && (
					<Box sx={{ px: 2, mt: 8 }}>
						<PayStep1
							onClickPay={handlePayStep}
							onClickSimplePay={handleSimplePayStep}
						/>
					</Box>
				)}
				{status === "PAY" && (
					<Box sx={{ px: 2, mt: 8 }}>
						<PayStep21 />
					</Box>
				)}
				{status === "SIMPLE_PAY" && (
					<Box sx={{ px: 2, mt: 8 }}>
						<PayStep22
							onClickNaverPay={handleNaverPay}
							onClickAliPay={handleAliPay}
							onClickWechatPay={handleWechatPay}
						/>
					</Box>
				)}
			</Container>
			{/* bottom =============================================== */}
			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					sx={{ bgcolor: "grey.soft", p: 3 }}
				>
					<Typography fontSize={20} fontWeight={700}>
						{t("total-pay")}
					</Typography>
					{/*  */}
					<Typography fontSize={20} fontWeight={700} color="primary.main">
						{/* {`${Number(total).toLocaleString()}원`} */}
						{t("price", { price: Number(total).toLocaleString() })}
					</Typography>
				</Stack>
				<BottomButtons
					right={{
						label: t("cancel"),
						onClick: () => {
							navigate(-1);
						},
					}}
				/>
			</Box>

			{/* 결제대기 안내모달 */}
			<AlertDialog
				open={alertWaiting}
				onClose={() => {
					setAlertWaiting(false);
					setPayType("");
				}}
				logo={alertIcon} // Replace with your logo URL
				title={alertTitle}
				description="결제 QR코드를 하단 스캐너에 인식해주세요."
			/>

			{/* 결제살패 안내모달 */}
			<Dialog
				open={payState}
				// open={true}
				onClose={() => {
					setPayState(false);
				}}
			>
				<DialogContent sx={{ pt: 5, textAlign: "center", width: 400 }}>
					<Typography variant="h3">{"알림"}</Typography>
					<Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
						{payStateDesc}
					</Typography>
				</DialogContent>
				<Divider />

				<DialogActions
					onClick={() => {
						setPayState(false);
					}}
				>
					<Box sx={{ textAlign: "center", width: "100%", cursor: "pointer" }}>
						<Typography variant="body1">확인</Typography>
					</Box>
				</DialogActions>
			</Dialog>
		</Layout>
	);
}
