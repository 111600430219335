import {useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
// components
import Layout from "layout/index";
import {Box, Container, Typography} from "@mui/material";
import {BottomButtons} from "components/unitComponent";

// translations
import {useTranslation} from "react-i18next";
import HomeIcon from "assets/icons/home-active.svg";

// ----------------------------------------------------------------------

export default function Complete() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { orderData } = location?.state;
  const [printed, setPrinted] = useState(false);

  function setReceipt() {
    if (printed) return alert(t("printed"));
    if (typeof ChromeAPI == "undefined") return;
    const body = {
      corpName: "주식회사 퀸즈스마일", // 회사이름
      corpNo: "766-87-00891", // 사업자번호
      addr: "대전광역시 유성구 773, 2층 201-가호 필로스페이스 비즈니스센터(에이치실 7호)", // 주소
      tel: "070-5165-3074", // 전화번호
      ...orderData,
    };
    ChromeAPI.toPrint(body);
    setPrinted(true);
    navigate("/");
  }

  return (
    <Layout
      sx={{
        px: "0 !important",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        "&:-webkit-scrollbar": { display: "none" },
      }}
    >
      <Container
        sx={{
          flexGrow: 1,
          px: "50px !important",
          "&:-webkit-scrollbar": { display: "none !important" },
        }}
      >
        <Typography fontSize={40} mt={4} color="primary.main" fontWeight={700}>
          {/* 결제가 완료되었습니다 */}
          {t("complete-pay")}
        </Typography>
        <Typography fontSize={25} mt={4} lineHeight={1.5}>
          {t("complete-order")}
        </Typography>
        <Typography fontSize={25} mt={3} mb={3} lineHeight={1.8}>
          {/* 감사합니다 :&#41; */}
          {t("thanku")}
        </Typography>

        {/*  */}

        <Typography fontSize={40} mt={10} color="primary.main" fontWeight={700}>
          Your order has been completed!
        </Typography>
        <Typography fontSize={25} mb={3} lineHeight={1.5}>
          {/* 감사합니다 :&#41; */}
          <br /> Foreign customers must take the receipt to confirm and receive
          your order. <br />
          <br />
          Thank you :&#41;
        </Typography>
      </Container>
      {/* bottom =============================================== */}
      <Box>
        <BottomButtons
          left={{
            label: t("back-home"),
            onClick: () => navigate("/"),
            icon: HomeIcon,
          }}
          right={{
            label: t("receipt"),
            onClick: () => setReceipt(),
          }}
        />
      </Box>
    </Layout>
  );
}
