import { create } from "zustand";
import Api from "utils/wApi/index";

const useCart = create((set) => ({
  festival: "",
  cart: [],
  total: 0,
  category: [],
  active: null,
  loading: false,
  countdown: new Date(),
  increaseCart: async (item) => {
    if (!!useCart.getState().loading) return;
    set((state) => ({ loading: true }));
    const originPrice = item.paymentPrice / item.buyCount;
    try {
      const updatedPrice = Math.floor(item.paymentPrice + originPrice);
      const { data } = await Api.updateCount(null, item.id, {
        params: {
          count: 1,
          // price: item.paymentPrice + originPrice,
          price: updatedPrice,
        },
      });
      await useCart.getState().callCart();
    } catch (error) {
      alert(error.message);
    }
    set((state) => ({ loading: false }));
  },
  decreaseCart: async (item) => {
    if (!!useCart.getState().loading) return;
    set((state) => ({ loading: true }));
    const originPrice = item.paymentPrice / item.buyCount;
    try {
      const updatedPrice = Math.floor(item.paymentPrice - originPrice);
      const { data } = await Api.updateCount(null, item.id, {
        params: {
          count: -1,
          // price: item.paymentPrice - originPrice
          price: updatedPrice
        },
      });
      await useCart.getState().callCart();
    } catch (error) {
      alert(error.message);
    }
    set((state) => ({ loading: false }));
  },
  removeCart: async (item) => {
    try {
      const { data } = await Api.removeOne(null, item.id);
      await useCart.getState().callCart();
    } catch (error) {
      alert(error.message);
    }
  },
  removeAllCart: async (item) => {
    try {
      const { data } = await Api.removeAll();
      await useCart.getState().callCart();
    } catch (error) {
      alert(error.message);
    }
  },
  callCart: async () => {
    try {
      const { data } = await Api.getCart();
      const total = data.list.reduce((acc, item) => acc + item.paymentPrice, 0);
      set((state) => ({ cart: data.list, total }));
    } catch (error) {
      alert(error.message);
    }
  },
  setCategory: async (item) => {
    set(() => ({ category: item }));
  },
  setActive: async (item) => {
    set(() => ({ active: item }));
  },
  setFestival: (value) => set(() => ({ festival: value })),
  setCountdown: (value) =>
    set((state) => {
      const date = new Date();
      date.setMinutes(date.getMinutes() + value);
      return { countdown: date };
    }),
}));

export default useCart;
