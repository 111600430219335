// ----------------------------------------------------------------------

export default function Tabs(theme) {
  return {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },

      styleOverrides: {
        root: {
          padding: 0,
          minWidth: 48,
          color: theme.palette.grey[300],
          fontWeight: 400,
          fontSize: 16,
          marginBottom: "-2px",

          "&.Mui-selected": {
            color: theme.palette.common.black,
            fontWeight: 700,
          },
        },

        labelIcon: {
          minHeight: 48,
          flexDirection: "row",
          "& > *:first-of-type": {
            marginBottom: 0,
            marginRight: theme.spacing(1),
          },
        },
        wrapper: {
          flexDirection: "row",
          whiteSpace: "nowrap",
        },
        textColorInherit: {
          opacity: 1,
          color: theme.palette.text.secondary,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          width: 48,
          borderRadius: "50%",
        },
      },
    },
  };
}
